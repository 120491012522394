import { useCallback, useEffect, useState } from "react";
import Storage from "@utils/storage";


export const useStorageKey = (key) => {
  const [value, setValue] = useState();

  const _setValue = useCallback(_value => {
    setValue(_value);
    Storage.set(key, _value);
  }, [key]);

  useEffect(() => {
    Storage.get(key).then(setValue);
  }, [key]);

  return [value, _setValue];
};
