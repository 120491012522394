

let getState = () => ({});
let dispatch = async () => null;
const getMcKey = () => getState()?.auth?.mcKey;
const getLoginToken = () => getState()?.auth?.loginToken;
const getCompanyId = () => (
  getState()?.auth?.companyInfo?.companyId || // 紫端
  getState()?.auth?.companyInfo?.id // 黑端
);
const getStaffId = () => getState()?.auth?.staffInfo?.id;
const getI18nMap = () => getState()?.i18n?.map || {};

const initGlobalUtils = store => {
  getState = store.getState;
  dispatch = store.dispatch;
};

export {
  getState,
  dispatch,
  initGlobalUtils,

  getMcKey,
  getLoginToken,
  getCompanyId,
  getStaffId,
  getI18nMap,
};
