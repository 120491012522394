import { request } from "../utils";
import { getCompanyId } from "@globalUtils";
import qs from "qs";

export const getSingle = ({ id }) => request(`/companies/${getCompanyId()}/shippingRecords/${id}`);

export const printShippingLabel = ({ id }) => request(`/companies/${getCompanyId()}/shippingRecords/${id}`);

// 取消发货记录的运单
export const voidTrackingNumber = ({ orderId, shippingRecordId }) => request(`/owners/${getCompanyId()}/orders/${orderId}/shippingLabels`, {
  method: "PATCH",
  data: {
    orderId,
    shippingRecordId,
    cancel: true,
  },
});
