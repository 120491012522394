import React, { useEffect } from "react";
import { StyleSheet, View } from 'react-native';
import { useNativeName, useMixedStyles } from "@hooks";
import { Teaset } from "ui-m/rn";
const { SegmentedBar } = Teaset;

const TabsByKey = props => {
  const {
    containerStyle, style, itemStyle, firstItemStyle, lastItemStyle, titleStyle, activeTitleStyle,
    primary,
    onChange, ...barProps
  } = props;
  const tabs = props.tabs;
  const defaultActiveKey = tabs.find(i => i.key === props.defaultActiveKey) ? props.defaultActiveKey : tabs[0]?.key;
  const activeKey = tabs.find(i => i.key === props.activeKey) ? props.activeKey : defaultActiveKey;

  const nativeName = useNativeName();
  const isMCPrimary = primary && ["WEB", "MC"].includes(nativeName);
  const styles = useMixedStyles({
    base: baseStyles,
    MC: mcStyles,
    MCS: mcsStyles,
    MS: msStyles,
    NANO: nanoStyles,
    overwriteStyle: isMCPrimary && mcPrimaryStyles, // 黑端额外支持primary样式
  });

  const indicatorLineColor = (
    isMCPrimary ? "#FFB43A" :
      nativeName === "MS" ? "#7265BA" :
        "#007AFF"
  );

  // 如果传入的activeKey不存在则自动切换到默认的tab
  useEffect(() => {
    if (activeKey && props.activeKey !== activeKey) {
      props.onChange?.(activeKey);
    }
  });

  let activeIndex = tabs.findIndex(i => i.key === activeKey);
  if (activeIndex === -1) {
    activeIndex = 0;
  }

  return (
    <View style={containerStyle}>
      <SegmentedBar
        justifyItem="scrollable"
        indicatorLineColor={indicatorLineColor}
        indicatorLineWidth={1}
        indicatorType="itemWidth"
        activeIndex={activeIndex}
        onChange={index => onChange?.(tabs[index].key)}
        animated={false}
        style={[styles.bar, style]}
        {...barProps}
      >
        {
          tabs.map((tab, tabIndex) => (
            <SegmentedBar.Item
              key={`bar-${tab.key}`}
              style={[
                styles.item,
                itemStyle,
                tabIndex === 0 && [styles.itemFirst, firstItemStyle],
                tabIndex === tabs.length - 1 && [styles.itemLast, lastItemStyle],
              ]}
              title={tab.name}
              titleStyle={[styles.titleStyle, titleStyle]}
              activeTitleStyle={[styles.activeTitleStyle, activeTitleStyle]}
            />
          ))
        }
      </SegmentedBar>
    </View>
  );
};

const baseStyles = StyleSheet.create({
  bar: {
    backgroundColor: '#fff',
  },
  item: {
    width: 'auto',
    alignItems: 'flex-end',
    paddingVertical: 12,
    paddingHorizontal: 5,
    marginHorizontal: 5,
  },
  itemFirst: {
    marginLeft: 15,
  },
  itemLast: {
    marginRight: 15,
  },
  titleStyle: {
    fontSize: 14,
    color: '#979797',
  },
  activeTitleStyle: {
    fontSize: 14,
    color: '#007AFF',
    fontWeight: '600',
  },
});

const mcStyles = StyleSheet.create({

});

const mcsStyles = StyleSheet.create({

});

const msStyles = StyleSheet.create({
  item: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginHorizontal: 15,
  },
  itemFirst: {
    marginLeft: 25,
  },
  itemLast: {
    marginRight: 25,
  },
  titleStyle: {
    color: '#0A0A0A',
  },
  activeTitleStyle: {
    color: '#7265BA',
  },
});

const nanoStyles = StyleSheet.create({

});

const mcPrimaryStyles = StyleSheet.create({
  bar: {
    backgroundColor: "#1F2327",
  },
  activeTitleStyle: {
    color: '#FFB43A',
  },
});

export default TabsByKey;
