import * as shippingRecordService from '../services/shippingRecord';
import { handleRequestError } from '@utils';

export default {
  namespace: 'shippingRecord',
  state: {

  },
  reducers: {

  },
  effects: {
    *getSingle({ payload }, { call, put, select }) {
      const res = yield call(shippingRecordService.getSingle, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *printShippingLabel({ payload }, { call, put, select }) {
      const res = yield call(shippingRecordService.printShippingLabel, payload);

      if (!res.success) {
        handleRequestError(res);
      }

      return res;
    },
    *voidTrackingNumber({ payload }, { call, put, select }) {
      const res = yield call(shippingRecordService.voidTrackingNumber, payload);

      // if (!res.success) {
      //   handleRequestError(res);
      // }

      return res;
    },
  },
};
