import { useEffect, useState } from "react";
import { useNativeName } from "./useNativeName";
import _ from "lodash";

export const useMixedStyles = (styleMap) => {
  const nativeName = useNativeName();
  const overwriteStyle = styleMap?.overwriteStyle;
  const baseStyle = styleMap?.base;
  const themeStyle = styleMap?.[nativeName === "WEB" ? "MC" : nativeName];
  const [style, setStyle] = useState({});

  useEffect(() => {
    setStyle(_.merge({}, baseStyle, themeStyle, overwriteStyle));
  }, [baseStyle, themeStyle, overwriteStyle]);

  return style;
};
