import React, { memo } from "react";
import { createStackNavigator, TransitionPresets, CardStyleInterpolators } from '@react-navigation/stack';
import _ from "lodash";
import { ensureValidObject } from "@utils/other";
import { Theme } from 'ui-m/rn';
import { View } from "react-native";
import NavigationBar from "@components/NavigationBar";

const Stack = createStackNavigator();

const navigatorPropKeys = ["id", "initialRouteName", "screenOptions", "detachInactiveScreens"];

const defaultScreenOption = {
  headerShown: false,
  cardStyle: { flex: 1 }, // web里如果缺了页面没法滚动
  animationEnabled: true,
  ...TransitionPresets.SlideFromRightIOS,
};

const modalScreenOption = {
  ...defaultScreenOption,
  ...TransitionPresets.ModalPresentationIOS,
};

const isFirstScreenOfCurrentStack = ({ route, navigation }) => {
  const state = navigation.getState();
  return (state?.routes?.[0]?.key === route?.key);
};

const getFirstScreenKeyInNestingRoutes = routes => {
  if (routes?.[0]?.state?.routes) {
    return getFirstScreenKeyInNestingRoutes(routes?.[0]?.state?.routes);
  }
  return routes?.[0]?.key;
};

const StackNavigator = memo(navigatorProps => {
  const {
    isRoot,
    isInFirstScreenOfRenderPage,
    isModalStack, // 是模态页面组
    isInModalStack, // 在一个模态页面组内
  } = navigatorProps;

  return (
    <Stack.Navigator {..._.pick(navigatorProps, navigatorPropKeys)}>
      {
        navigatorProps.screens.map(screenProps => {
          const {
            modal,
            name,
            component: Component = View,
            options,
          } = screenProps;

          return (
            <Stack.Screen
              key={name}
              name={name}
              options={({ route, navigation }) => {
                const _options = {
                  ...(modal ? modalScreenOption : defaultScreenOption),
                  ...ensureValidObject(options),
                };

                // 推入一个stack时，用的动画是包含该stack的Screen的动画，并直接渲染了stack内指定的一个Screen
                // stack内切换Screen时，会用对应Screen的动画
                // navigation是最近一层的Stack.Navigator
                // 综上，当前Screen如果是其Navigator的第一个屏幕，则不需要动画
                if (!modal && isFirstScreenOfCurrentStack({ route, navigation })) {
                  _options.animationEnabled = false;
                }

                return _options;
              }}
              component={(_props) => {
                /*
                  modal: 如果是页面，则是独立模态页；如果是页面组，则是模态页面组
                  screens: [
                      screen
                      screen modal => isInModalStack => modalPresentation 底部弹出
                      screen stack
                          screen
                          screen
                      screen stack modal 底部弹出
                          screen => isInModalStack => modalPresentation
                          screen => isInModalStack
                          screen stack
                              screen => isInModalStack
                              screen => isInModalStack
                          screen stack modal 底部弹出
                              screen => isInModalStack => modalPresentation
                              screen => isInModalStack
                  ]

                  页面依赖的路由参数:
                    isInModalStack:
                      效果: 如果页面是模态形式的, 页头会增加一部分上边距, 且不兼容状态栏高度
                      场景: 所有模态形式的页面
                      判断逻辑:
                        如果所属的 Screen 是模态形式
                        如果所属的 Navigator 是属于模态形式的 Screen


                    modalPresentation:
                      效果: 页面的后退按钮默认样式为 X
                      场景: 所有模态形式的独立页面或模态页面组的第一个页面
                      判断逻辑:
                        如果所属的 Screen 是模态形式
                        如果所属的 Navigator 是属于模态形式的 Screen, 且是其中的第一个Screen

                    isFirstPage:
                      效果: 页面的后退按钮默认样式为 X
                      场景: 根路由的第一个页面
                      判断逻辑:
                        如果 根Navigator 的第一个 Screen 是独立页面
                        如果 根Navigator 的第一个 Screen 是页面组, 则标记其第一个页面组不断往下寻找

                    *** 注意事项 ***
                      useNavigationState 只能获取所属 Navigator 的 routes, 嵌套路由不方便查找
                      如果在 根Navigator 下用 useNavigationState, 可能造成非预期的页面重置, 导致页面过渡动画不正常

                 */

                const _isFirstScreenOfCurrentStack = isFirstScreenOfCurrentStack(_props);
                const _isInModalStack = modal || isInModalStack;
                const _modalPresentation = modal || (isModalStack && _isFirstScreenOfCurrentStack);
                // 属于第一个直接渲染页面的屏幕
                const _isInFirstScreenOfRenderPage = (
                  (isRoot && _isFirstScreenOfCurrentStack) ||
                  (isInFirstScreenOfRenderPage && _isFirstScreenOfCurrentStack)
                );

                if (screenProps.screens) {
                  return (
                    <StackNavigator
                      {..._.pick(screenProps, navigatorPropKeys)}
                      screens={screenProps.screens}
                      isModalStack={modal}
                      isInModalStack={_isInModalStack}
                      isInFirstScreenOfRenderPage={_isInFirstScreenOfRenderPage}
                    />
                  );
                }

                return (
                  <Component
                    {..._props}
                    isInModalStack={_isInModalStack}
                    isModalPage={_isInModalStack}
                    modalPresentation={_modalPresentation}
                    isFirstPage={_isInFirstScreenOfRenderPage}
                    pageContainerStyle={_isInModalStack && {
                      marginTop: Theme.navBarContentHeight + NavigationBar.MODAL_BAR_TOP_SPACE,
                    }}
                  />
                );
              }}
            />
          );
        })
      }
    </Stack.Navigator>
  );
});

export default StackNavigator;
