import { createGuid, createSharedPromise, ensureValidObject } from ".";

const stepper = (() => {
  let i = 0;
  return () => ++i;
})();

const eventListenerMap = {};

// handler: { name: string, data: object } => any
const addListener = (name, handler) => {
  if (!eventListenerMap[name]) {
    eventListenerMap[name] = [];
  }
  const id = stepper();
  const listener = {
    id,
    name,
    handler,
    remove: () => removeListener(name, id),
  };
  eventListenerMap[name].push(listener);
  return listener;
};

const removeListener = (name, id) => {
  if (eventListenerMap[name]) {
    eventListenerMap[name] = eventListenerMap[name].filter(i => i.id !== id);
  }
};

const addOnceListener = (name, handler) => {
  const listener = addListener(name, (...rest) => {
    listener.remove();
    handler(...rest);
  });
  return listener;
};

const dispatchEvent = ({ name, data }) => {
  if (eventListenerMap[name]) {
    [...eventListenerMap[name]].map(listener => {
      listener.handler({ name, data });
    });
  }
};

const commissionMap = {};
// 委托: 将event与promise结合
const setCommission = (options) => {
  options = ensureValidObject(options);
  if (
    !options.complete &&
    !options.cancel &&
    !options.callback
  ) {
    return;
  }
  const noop = () => { };
  const commissionId = createGuid();
  const complete = options.complete || options.callback || noop;
  const cancel = options.cancel || options.callback || noop;

  const [promise, resolve] = createSharedPromise();
  commissionMap[commissionId] = {
    complete: data => resolve({ type: "complete", payload: data }),
    cancel: () => resolve({ type: "cancel" }),
  };
  promise.then((action) => {
    delete commissionMap[commissionId];
    const { type, payload } = action;
    type === "complete" ? complete(payload) : cancel();
  });
  return commissionId;
};

const getCommission = commissionId => {
  return commissionMap[commissionId];
};

const completeCommission = (commissionId, data) => {
  return getCommission(commissionId)?.complete?.(data);
};

const cancelCommission = commissionId => {
  return getCommission(commissionId)?.cancel?.();
};

const getOrSetCommission = param => {
  return (
    (typeof param === "object" && param) ? setCommission(param) :
      typeof param === "string" ? getCommission(param) :
        undefined
  );
};

function cancelAllPageCommissionWhenWillUnmount() {
  const _componentWillUnmount = this.componentWillUnmount;
  this.componentWillUnmount = function () {
    Object.values(ensureValidObject(this.props?.route?.params)).map(cancelCommission);
    _componentWillUnmount.call(this);
  };
}

getOrSetCommission.get = getCommission;
getOrSetCommission.set = setCommission;
getOrSetCommission.complete = completeCommission;
getOrSetCommission.cancel = cancelCommission;
getOrSetCommission.autoCancel = cancelAllPageCommissionWhenWillUnmount;

const AppEvent = {
  on: addListener,
  once: addOnceListener,
  dispatch: dispatchEvent,
  Commission: getOrSetCommission,
};

export default AppEvent;

