import resources from './resources';

export default [
  {
    key: 'toastSucceed',
    source: require('../assets/toast/succeed.png'),
    width: 44,
    height: 44,
  },
  {
    key: 'toastFailed',
    source: require('../assets/toast/failure.png'),
    width: 44,
    height: 44,
  },
  {
    key: "loadingMini",
    source: resources.spinnerMini,
    width: 15,
    height: 15,
  },
  // cell
  {
    key: 'cellDrag',
    source: resources.cellDrag,
    width: 20,
    height: 20,
  },
  {
    key: 'cellCopy',
    source: resources.cellCopy,
    width: 20,
    height: 20,
  },
  {
    key: "cellEdit",
    source: resources.cellEdit,
    width: 20,
    height: 20,
  },
  {
    key: "cellMore",
    source: resources.cellMore,
    width: 20,
    height: 20,
  },
  {
    key: "cellRefresh",
    source: resources.cellRefresh,
    width: 18,
    height: 18,
  },
  // menu
  {
    key: "menuDelete",
    source: resources.menuDelete,
    width: 20,
    height: 20,
  },
  {
    key: "menuDuplicate",
    source: resources.menuCopy,
    width: 20,
    height: 20,
  },
  {
    key: "menuExport",
    source: resources.menuExport,
    width: 20,
    height: 20,
  },
  {
    key: "menuSearch",
    source: resources.menuSearch,
    width: 20,
    height: 20,
  },
  // 零散
  {
    key: "questionMark",
    source: resources.questionMark,
    width: 20,
    height: 20,
  },
  {
    key: "closeGray",
    source: resources.closeGray,
    width: 30,
    height: 30,
  },
];
