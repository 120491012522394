import React from 'react';
import { BackHandler, Dimensions, StyleSheet, View } from 'react-native';
import { Teaset, Theme } from 'ui-m/rn';
import BasePage from './BasePage';
import NavigationBar from './NavigationBar';
import { getDefaultNavigationBarPrimary } from '@utils/other';


export default class NavigationPage extends Teaset.NavigationPage {

  static defaultProps = {
    ...Teaset.NavigationPage.defaultProps,
    navigationBarProps: {},
  }

  static NavigationBar = NavigationBar

  get needInputBottomSpace() {
    return (
      Theme.isWebInAPP &&
      !Theme.isIOSWeb &&
      ["MC", "MCS"].includes(this.props?.nativeName || this.props?.app?.nativeInfo?.name)
    );
  }

  get isBigScreen() {
    return Dimensions.get('window').width > 800;
  }

  renderNavigationBar = () => {
    const {
      title,
      showBackButton,
      backBtnIcon,
      renderNavigationTitle,
      renderNavigationLeftView,
      renderNavigationRightView,
      statusBarStyle,
      isInModalStack,
      modalPresentation,
      isFirstPage,
      primary = getDefaultNavigationBarPrimary,
      hideBottomBorder,
    } = this.props;
    return <NavigationBar {...{
      navigation: this.props.navigation, // 实现页面切换用
      title: renderNavigationTitle === undefined ? title : renderNavigationTitle.call(this),
      showBackButton,
      backBtnIcon,
      hideBottomBorder: typeof hideBottomBorder === "function" ? hideBottomBorder.call(this) : hideBottomBorder,
      leftView: (showBackButton || renderNavigationLeftView === undefined) ? undefined : renderNavigationLeftView.call(this),
      rightView: renderNavigationRightView === undefined ? undefined : renderNavigationRightView.call(this),
      primary: typeof primary === "function" ? primary.call(this) : primary,
      statusBarStyle,
      isInModalStack,
      modalPresentation,
      isFirstPage,
      ...this.props.navigationBarProps,
    }} />;
  }

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this._backHandle);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this._backHandle);
  }

  _backHandle = () => {
    if (this.props?.isGestureBackEnabled === false) {
      // REF: https://reactnative.dev/docs/backhandler
      return true;
    }
  }

  renderWebModalPage = () => {
    let {style, pageContainerStyle, children, scene, autoKeyboardInsets, keyboardTopInsets, title, showBackButton, navigationBarInsets, ...others} = this.props;

    let {left: paddingLeft, right: paddingRight} = Theme.screenInset;
    let pageContainerFs = [{
      flex: 1,
      paddingLeft,
      paddingRight,
      marginTop: navigationBarInsets ? (Theme.navBarContentHeight + Theme.statusBarHeight) : 0,
    }, StyleSheet.flatten(pageContainerStyle)];

    return (
      <View style={this.buildStyle()} onLayout={e => this.onLayout(e)} {...others}>
        <View style={{flex: 1}} >
          <View style={pageContainerFs}>
            {this.renderPage()}
          </View>
          {this.renderNavigationBar()}
        </View>
      </View>
    );
  }

  render() {
    BasePage.renderDocumentTitle(this);

    // if (this.props.isModalPage && this.isBigScreen) {
    //   return this.renderWebModalPage();
    // }
    return super.render();
  }
}
